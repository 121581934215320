import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import CatalogProductSection from '../components/CatalogProductSection';
import BreadCrump from '../components/BreadCrump';
import { useStore } from '../context/store';

const CatalogPath = () => {
  const { catalog } = useParams();
  const { openMenu } = useStore();

  return (
    <div className="back-box">
      <Header />
      <main hidden={openMenu}>
        <BreadCrump breadcrumb={[{ name: 'Каталог' }]} />
        <div className="catalog-page">
          <CatalogProductSection catalog={catalog} />
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default CatalogPath;

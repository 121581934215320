import { FaTelegramPlane } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import NewLogo from '../img/new_logo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__inner container">
        <div className="row">
          <div className="col-sm-3 col-md-2">
            <a className="footer__logo-wrap" href="/">
              <img
                style={{ height: '35px', width: '100px' }}
                className="footer__logo"
                src={NewLogo}
                alt=""
              />
            </a>
          </div>
          <div className="footer__social-wrap col-sm-12 col-md-3">
            <div className="footer__social-set">
              <span className="footer__social-title" data-localize="g.join us">
                Присоединяйтесь!
              </span>
              <div className="footer__social social">
                <div className="social__title" data-localize="g.country">
                  Kazakhstan
                </div>
                <div className="social__set">
                  {/* <Link
                    to="https://www.facebook.com/lalaku.uzbekistan/"
                    target="_blank"
                    className="social__icon-wrap"
                  >
                    <div className="social__icon social__icon_fb"></div>
                  </Link> */}
                  <Link
                    to="https://www.instagram.com/Lalaku.Kazakhstan/"
                    target="_blank"
                    className="social__icon-wrap"
                  >
                    <div className="social__icon social__icon_insta"></div>
                  </Link>
                  <Link
                    to="https://t.me/+77079631989"
                    target="_blank"
                    className="social__icon-wrap text-white text-decoration-none"
                  >
                    <div className="fa-lg">
                      <FaTelegramPlane />
                    </div>
                  </Link>
                  {/* <Link
                    to="https://www.youtube.com/channel/UCCHvBrlmF-gCUR60zFiAexw"
                    target="_blank"
                    className="social__icon-wrap text-white text-decoration-none"
                  >
                    <div className="fa-lg">
                      <FaYoutube />
                    </div>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-9 col-md-6 px-0">
            <nav className="footer__menu">
              <div className="col-6">
                <h4>Контакт</h4>
                <a
                  href="tel:+77079631989"
                  className="d-block mt-2 text-decoration-none"
                >
                  +7 707 963 19 89
                </a>
              </div>
              <div className="col-6 mt-lg-0">
                <h4>Email</h4>
                <a
                  href="mailto:info@lalaku.kz"
                  className="d-block text-decoration-none"
                >
                  info@lalaku.kz
                </a>
                {/* <a
                  href="mailto:info@sofgigienik.com"
                  className="d-block text-decoration-none mt-2"
                >
                  info@sofgigienik.com
                </a>
                <a
                  href="mailto:llcsofgigienik@gmail.com"
                  className="d-block text-decoration-none mt-2"
                >
                  llcsofgigienik@gmail.com
                </a> */}
              </div>
            </nav>
          </div>
          <div className="col-12 col-md-7">
            <div className="footer__warning">
              <p hidden>
                Посещение нашего сайта и использование представленной на нем
                информации регулируются Правовыми положениями. Пожалуйста,
                ознакомьтесь с нашей Политикой конфиденциальности.
              </p>
            </div>
            <div className="footer__copyright">
              <p data-localize="g.rights">© 2023. Все права защищены</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface AdvancedSliderProps {
  slides: string[];
}
export function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} arrow-icon`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

export function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} arrow-icon-prev`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

const AdvancedSlider: React.FC<AdvancedSliderProps> = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    draggable: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Slider className="home-banner__slider" {...settings}>
      {slides.map((slide, index) => (
        <img
          key={index}
          className="home-banner__img home-banner__img_desk"
          src={slide}
          alt={`Slide ${index + 1}`}
        />
      ))}
    </Slider>
  );
};

export default AdvancedSlider;

const VideosSection = () => {
  return (
    <section className="container">
      <h2
        className="catalog-faq__header h2"
        style={{ textTransform: 'capitalize' }}
      >
        Videos
      </h2>
      <iframe
        className="video-responsive"
        src="https://www.youtube.com/embed/hP53ccPJJoo"
        title="Прокладки для подмышек  Lalaku Pads | Qo'ltiq ostidagi ter yostiqchalari Lalaku Pads"
      ></iframe>
      <iframe
        className="video-responsive"
        src="https://www.youtube.com/embed/u2sp30tbjQI"
        title="Еще одно удобство для наших дорогих родителей от “Lalaku”! | `Lalaku` dan aziz ota-onalarimizga yana bir qulaylik!"
      ></iframe>
      <iframe
        className="video-responsive"
        src="https://www.youtube.com/embed/X0uvICTaB0U"
        title="Lalaku для детей | LaLaku bolalar uchun"
      ></iframe>
      <iframe
        className="video-responsive"
        src="https://www.youtube.com/embed/hvUGhWx_xKI"
        title="Лалаку доставит радость вашему ребенку | Lalaku bolangizga quvonch bahshida etadi"
      ></iframe>
      <iframe
        className="video-responsive"
        src="https://www.youtube.com/embed/mXL9ZILK6LI"
        title="Маски KN 95 от компании Lalaku | Lalakudan KN 95 maskalari"
      ></iframe>
    </section>
  );
};

export default VideosSection;

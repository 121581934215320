import Catalog from './pages/Catalog';
import CatalogPath from './pages/CatalogPath';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Sales from './pages/Sales';
// import Partners from './pages/Partners';
import About from './pages/About';
import { Helmet } from 'react-helmet';
import NotFound from './pages/NotFound';

function App() {
  return (
    <>
      <Router>
        <Helmet>
          <title>Главная страница</title>
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/catalog" element={<Catalog />} />
          {/* <Route path="/sales" element={<Sales />} />
          <Route path="/partners" element={<Partners />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/catalog/:catalog" element={<CatalogPath />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

import { Link } from 'react-router-dom';
import { catalogs } from '../api/catalog';

const SectionCatalog = () => {
  return (
    <section className="home-page__catalog catalog container">
      <div className="catalog__heading">
        <h2 className="catalog__main-header h2" data-localize="g.title.2">
          Каталог
        </h2>
        <Link
          className="catalog__full btn btn_color_still btn_color_still-1"
          to="/catalog/diapers"
        >
          Полный каталог
        </Link>
      </div>
      <div className="catalog__slider">
        {catalogs.map((cat, index) => (
          <div className="catalog__slide content-block" key={index}>
            <div className="catalog__header">
              <h3 className="catalog__title h3">{cat.title}</h3>
              <span className="catalog__subtitle">{cat.subtitle}</span>
            </div>
            <div className="catalog__img-wrap">
              <img className="catalog__img" src={cat.img} alt={cat.title} />
            </div>
            <div className="catalog__subslider-wrap">
              <div id="catalog_prod-1" className="catalog__subslider">
                <div className="catalog__subslide">
                  <img
                    className="catalog__subslide-img"
                    src={cat.icon1}
                    alt=""
                  />
                  <div className="catalog__subslide-text">{cat.info1}</div>
                </div>
                <div className="catalog__subslide">
                  <img
                    className="catalog__subslide-img"
                    src={cat.icon2}
                    alt=""
                  />
                  <div className="catalog__subslide-text">{cat.info2}</div>
                </div>
                <div className="catalog__subslide">
                  <img
                    className="catalog__subslide-img"
                    src={cat.icon3}
                    alt=""
                  />
                  <div className="catalog__subslide-text">{cat.info3}</div>
                </div>
              </div>
            </div>
            <div className="catalog__more">
              <Link
                to={cat.url}
                className="catalog__more-button btn btn_color_bright-1"
              >
                Подробнее
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SectionCatalog;

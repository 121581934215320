import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SampleNextArrow, SamplePrevArrow } from './AdvSlider';
import data from '../api/data.json';
import { useState } from 'react';

const FAQSection = () => {
  const [selected, setSelected] = useState<number[]>([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplaySpeed: 3000,
    draggable: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: data.faqs.length > 1 ? 2 : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
        },
      },
    ],
  };

  const handleAddOrRemove = (id: number) => {
    if (!isNaN(id)) {
      if (selected.includes(id)) {
        setSelected(selected.filter((num) => num !== id));
      } else {
        setSelected([...selected, id]);
      }
    }
  };

  return (
    <section className="catalog-faq container">
      <div className="catalog-faq__heading">
        <h2 className="catalog-faq__header h2">Часто задаваемые вопросы</h2>
      </div>
      <div className="catalog-faq__slider slick-slider">
        <Slider className="calalog__slider" {...settings}>
          {data.faqs.map((faq) => (
            <div className="catalog__slider-box" key={faq.id}>
              <label className="catalog-faq__slide">
                <input
                  className="catalog-faq__slide-checkbox"
                  type="checkbox"
                  tabIndex={0}
                  onChange={() => handleAddOrRemove(faq.id)}
                />
                <div className="catalog-faq__slide-inner">
                  <div
                    className={`catalog-faq__question catalog-faq__question${
                      selected.includes(faq.id) && '--rotate'
                    }`}
                  >
                    <p>{faq.question}</p>
                  </div>
                  <div
                    className={`catalog-faq__answer catalog-faq__answer${
                      selected.includes(faq.id) && '--rotate'
                    } `}
                  >
                    <p
                      className="catalog-faq__answer-text"
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    />
                  </div>
                </div>
              </label>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default FAQSection;

const SubscribeSection = () => {
  return (
    <section className="subscribe container">
      <div className="subscribe__inner ">
        <img
          className="subscribe__letter"
          src="https://www.sofgigienik.com/template/sofgigienik/img/b-subscribe/letter.svg"
          alt=""
        />
        <div className="subscribe__heading">
          <img
            className="subscribe__icon"
            src="https://www.sofgigienik.com/template/sofgigienik/img/b-subscribe/newspaper.svg"
            alt=""
          />
          <div className="subscribe__header">
            <div className="subscribe__title">
              Узнавайте о новых статьях первыми
            </div>
            <div className="subscribe__subtitle">
              Никаких долгих поисков достоверной информации на просторах
              интернета. Только самые актуальные и полезные статьи для будущих и
              настоящих мамочек от LALAKU! Подпишитесь, чтобы не пропустить!
            </div>
          </div>
        </div>
        <form
          className="subscribe__form"
          id="subscribeForm"
          method="POST"
          action="#"
        >
          <div className="status"></div>
          <input
            className="subscribe__form-email input input-text"
            id="email"
            name="email"
            type="email"
            placeholder="E-mail"
          />
          <input
            type="submit"
            style={{ display: 'none' }}
            value="submit"
            name="submit"
          />
          <button
            type="button"
            id="subscribeButton"
            className="subscribe__form-submit btn btn_color_bright-1 sb_news"
          >
            Подписаться
          </button>
        </form>
      </div>
    </section>
  );
};

export default SubscribeSection;

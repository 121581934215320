import React from 'react';
import { Link } from 'react-router-dom';
type BreadCrumpProps = {
  href?: string;
  name: string;
};
type Props = {
  breadcrumb?: BreadCrumpProps[];
};
const BreadCrump = ({ breadcrumb }: Props) => {
  return (
    <nav className="breadcrumb container">
      <Link className="breadcrumb__item_first" to="/">
        Главная
      </Link>
      {breadcrumb?.map((val, index) => {
        return (
          <Link
            key={index}
            className="breadcrumb__item"
            to={`${val.href || '#'}`}
          >
            {val.name}
          </Link>
        );
      })}
    </nav>
  );
};

export default BreadCrump;

import { useState } from 'react';
import data from '../api/data.json';
import { Link } from 'react-router-dom';

type Props = {
  id: number;
  brand: string;
  title?: string;
  description?: string;
  gallery: string[];
  box?: {
    size?: string;
    weight?: string;
  }[];
  first_text?: string;
  second_text?: string;
  sizes: string[];
};
type CatProp = {
  cat: Props;
};

const ProductBox: React.FC<CatProp> = ({ cat }) => {
  const [selected, setSelected] = useState<number>();
  const [isSelect, setIsSelect] = useState<boolean>(false);

  return (
    <div className="catalog-products__slide content-block">
      <div className="catalog-products__header">
        <Link className="link-nounderline tyi" to="/catalog">
          <h3 className="catalog-products__title h3">{cat.title}</h3>
        </Link>
      </div>

      <div className="catalog-products__img-wrap">
        <Link className="link-nounderline tyi" to="/catalog">
          <img
            className="catalog-products__img"
            alt={cat.title}
            src={selected ? cat.gallery[selected] : cat.gallery[0]}
          />
        </Link>
      </div>
      <div className="catalog-products__subslider-wrap">
        {cat.brand === 'lalaku'
          ? data.brand_info.lalaku
          : cat.brand === 'mask'
          ? data.brand_info.mask
          : cat.description}
      </div>
      <div className="catalog-products__sizes">
        <div className="diapers-sizes">
          <span className="diapers-sizes__header">
            <span>{cat.first_text}</span>
            <span>{cat.second_text}</span>
          </span>
          {cat.box?.map((val, index) => {
            return (
              <span
                key={index}
                onClick={() => {
                  setIsSelect(cat.sizes.length ? true : false);
                  setSelected(selected === index ? undefined : index);
                }}
                className={`diapers-sizes__tile diapers-sizes__tile_hover-effect ${
                  index === selected && 'diapers-sizes__tile_active'
                }`}
              >
                {val.size && (
                  <span className="diapers-sizes__size">{val.size}</span>
                )}
                {val.weight && (
                  <span className="diapers-sizes__weight">{val.weight}</span>
                )}
              </span>
            );
          })}
        </div>
      </div>
      {isSelect && (
        <div className="catalog-products__note">
          <span className="catalog-products__note-main">
            Среднее количество* подгузников в день для этого размера:{' '}
            {cat.sizes[selected ? selected : 0]} штук
          </span>
          <span className="catalog-products__note-second">
            *количество может быть индивидуальным
          </span>
        </div>
      )}
    </div>
  );
};

export default ProductBox;

import Header from '../components/Header';
import Footer from '../components/Footer';
import BreadCrump from '../components/BreadCrump';
import CatalogProductSection from '../components/CatalogProductSection';
import FAQSection from '../components/FAQSection';
import SubscribeSection from '../components/SubscribeSection';
import { useStore } from '../context/store';

const Catalog = () => {
  const { openMenu } = useStore();
  return (
    <div className="back-box">
      <Header />
      <main hidden={openMenu}>
        <BreadCrump breadcrumb={[{ name: 'Каталог' }]} />
        <div className="catalog-page">
          <CatalogProductSection catalog="" />
          <FAQSection />
          <SubscribeSection />
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default Catalog;

import Slide1 from '../img/slide1.jpg';
import Slide2 from '../img/slide2.jpg';
import Slide3 from '../img/slide3.jpg';

const quests = [
  {
    id: 1,
    img: Slide1,
    title: 'Остаются сухими надолго',
    subtitle:
      'Новый суперабсорбент моментально впитывает влагу, равномерно распределяя ее и удерживая внутри подгузника.',
  },
  {
    id: 2,
    img: Slide2,
    title: 'Самое бережное прикосновение',
    subtitle:
      'Используя инновационные технологии мы создали ультрамягкие дышащие материалы, которые не натирают кожу',
  },
  {
    id: 3,
    img: Slide3,
    title: 'Безопасные и гипоаллергенные',
    subtitle:
      'LALAKU не содержат лосьонов и отдушек, бережно защищают нежную кожу малыша от раздражений.',
  },
];

const SectionQuest = () => {
  return (
    <section className="container home-page__quest">
      <h2 className="home-quest__header h2" data-localize="g.title.1">
        Почему LALAKU так хорош?
      </h2>
      <div className="home-quest__slider slider slider_light-dots">
        {quests.map((quest) => {
          return (
            <div
              key={quest.id}
              className="home-quest__slide"
              aria-hidden="true"
              role="tabpanel"
              id="slick-slide110"
              aria-describedby="slick-slide-control110"
            >
              <img
                className="home-quest__slide-img"
                src={quest.img}
                alt={quest.title}
              />
              <div className="home-quest__slide-text">
                <div
                  className="home-quest__slide-title"
                  data-localize="g.title-1.t1"
                >
                  {quest.title}
                </div>
                <div
                  className="home-quest__slide-subtitle"
                  data-localize="g.title-1.text1"
                >
                  {quest.subtitle}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SectionQuest;

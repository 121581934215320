// NotFound.js
import { Helmet } from 'react-helmet';
import NotFoundImg from '../img/not-found.png';
function NotFound() {
  return (
    <>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <div className="error" id="error">
        <div className="container">
          <div className="content centered">
            <img style={{ width: '500px' }} src={NotFoundImg} alt="not found" />
            <h1 style={{ color: '#000' }}>
              Oops, looks like the page is lost.
            </h1>
            <p
              style={{ fontSize: '22px' }}
              className="sub-header text-block-narrow"
            >
              This is not a fault, just an accident that was not intentional.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
